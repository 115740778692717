import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AuthDialog from "./AuthDialog";
import { StatesDropDown } from "../../common/StatesDropDown";
import { registerUser, selectUsers } from "../users/usersSlice";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { setXClientHost } from "../../app/utils.js";
import { selectCustomisation } from "../customisation/customisationSlice";

import { uuidv4 } from "../../app/utils";
import { fetchClientCountry, selectClients } from "../clients/clientsSlice.js";
import {
  fetchManagersPublic,
  fetchMemberGroupsPublic,
} from "../members/membersSlice.js";
import { fetchStoresPublic, selectStores } from "../stores/storesSlice.js";
import { fetchMemberGroups, selectMembers } from "../members/membersSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    border: `2px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
  },
  textLabel: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 12,
  },
  textInput: {},
  headerItem: {
    color: theme.palette.primaryFont.main,
  },

  conditions: {
    color: theme.palette.secondaryFont.main,
  },

  backButton: {
    color: "#fff",
    border: "1px solid",
    borderColor: "#fff",
    borderRadius: 16,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
  },
  asterisk: {
    color: "red",
  },
}));

export default function Register() {
  const classes = useStyles();
  const { customisation } = useSelector(selectCustomisation);
  const client = useSelector(selectClients);
  const { storesList } = useSelector(selectStores);
  const { memberGroupsList, managersList } = useSelector(selectMembers);

  const location = useLocation();
  const [selectedStore, setSelectedStore] = useState(null);
  const [memberGroup, setMemberGroup] = useState(null);
  const [manager, setManager] = useState(null);

  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:850px)");
  const { error, success } = useSelector(selectUsers);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [externalID, setExternalID] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [poBox, setPoBox] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [checked, setChecked] = useState(false);

  const [streetNumberError, setStreetNumberError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const passwordRef = useRef(null);
  const mobileRef = useRef(null);
  const companyRef = useRef(null);
  const streetRef = useRef(null);

  useEffect(() => {
    if (success) {
      setUsername("");
      setPassword("");
      setFirstName("");
      setLastName("");
      setDisplayName("");
      setEmail("");
      setMobile("");
      setPoBox("");
      setStreetNumber("");
      setStreetName("");
      setCompanyName("");
      setSuburb("");
      setPostCode("");
      setCity("");
      setState("");
      setCountry("");
      setPasswordError("");
      setConfirmError("");
      setConfirmPassword("");
      setChecked(false);
    }
  }, [success]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const isPOBox = (str) => {
    const regTest =
      /\b(p\.?\s*o\.?|post\s+office|post)(\s+)?(?:box|[0-9]*)?\b/i;
    return regTest.test(str);
  };

  useEffect(() => {
    dispatch(fetchClientCountry({}));
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_REFERRAL == "true") {
      dispatch(
        fetchStoresPublic({
          query: "?limit=25",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_REFERRAL == "true") {
      const queryParams = new URLSearchParams(location.search);
      const branch = queryParams.get("branch");

      if (branch && storesList.length > 0) {
        const cleanedBranch = decodeURIComponent(branch).replace(/\s+/g, ""); // Decode and remove spaces from branch name
        const foundStore = storesList.find(
          (store) => store.name.replace(/\s+/g, "") === cleanedBranch
        );
        if (foundStore) {
          setSelectedStore(foundStore);
        }
      }
    }
  }, [storesList, location.search]);

  useEffect(() => {
    if (process.env.REACT_APP_REFERRAL == "true") {
      if (selectedStore) {
        dispatch(
          fetchMemberGroupsPublic({
            query: `?store_id=${selectedStore.id}&limit=100`,
          })
        );
      }
    }
    if (process.env.REACT_APP_ACD_BRANCH == "true") {
      if (selectedStore) {
        dispatch(
          fetchManagersPublic({
            query: `?role_id=4&store_id=${selectedStore.id}&limit=100`,
          })
        );
      }
    }
  }, [selectedStore]);

  const validatePassword = () => {
    var conditions = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,99}$/;
    if (password.match(conditions)) {
      return true;
    } else {
      return false;
    }
  };

  const submitRegistration = (event) => {
    event.preventDefault();
    if (isPOBox(streetNumber)) {
      setStreetNumberError("We are unable to accept a PO Box address");
      streetRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (isPOBox(streetName)) {
      setStreetNumberError("");
      setStreetNameError("We are unable to accept a PO Box address");
      streetRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      if (!validatePassword()) {
        setPasswordError("Invalid password");
        passwordRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (password !== confirmPassword) {
        setConfirmError("Passwords don't match");
        passwordRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        setPasswordError("");
        setConfirmError("");
        setStreetNumberError("");
        setStreetNameError("");
        const userDetails = {
          statusID: 1,
          clientID: Number(process.env.REACT_APP_CLIENT_ID),
          username: username,
          firstName: firstName,
          lastName: lastName,
          displayName: firstName + " " + lastName,
          email: email,
          password: password,
          mobile:
            mobile == ""
              ? uuidv4()
              : mobile.startsWith("+")
              ? mobile
              : client.country.countryName == "Australia"
              ? "+61" + mobile.replace(/^0+/, "")
              : "+64" + mobile.replace(/^0+/, ""),
          roleID: 4,
          company: company,
          externalID: externalID,
          storeName: selectedStore ? selectedStore.name : null,
          managerName: manager ? manager : null,
          memberGroupName: memberGroup ? memberGroup : null,
          postalAddress: {
            poBox: poBox,
            streetNumber: streetNumber,
            companyName: companyName,
            streetName: streetName,
            suburb: suburb,
            postCode: postCode,
            city: city,
            state: state,
            country: country,
          },
        };
        dispatch(
          registerUser({
            user: userDetails,
            host: setXClientHost(),
          })
        );
        setResetSuccess(success);
        setDialogError(error);
      }
    }
  };

  return (
    <div className={classes.paper} key={success}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
      >
        {matches && (
          <Grid item xs={3} style={{ padding: 50 }}>
            <Grid item xs>
              <Link to="/signin" style={{}}>
                <div className={classes.backButton}>Back</div>
              </Link>
            </Grid>
          </Grid>
        )}
        <Grid
          style={{
            maxWidth: matches ? "50%" : "100%",
            padding: matches ? "32px" : "0px !important",
          }}
          className={classes.registerContainer}
          item
          xs={matches ? 6 : 12}
        >
          <form className={classes.form} onSubmit={submitRegistration}>
            <div
              style={{
                fontSize: matches ? 40 : 28,
                fontWeight: "bold",
                marginBottom: matches ? 16 : 8,
              }}
            >
              {process.env.REACT_APP_CLIENT_ID == "12"
                ? "Register for Taylors Pour To Score"
                : `Register for ${customisation.theme.clientName}`}
            </div>
            <div
              style={{
                fontSize: matches ? 20 : 18,
                fontWeight: "bold",
                marginBottom: matches ? 16 : 8,
              }}
            >
              {process.env.REACT_APP_CLIENT_ID == "12"
                ? "Please enter your details to register for this Partnership Program"
                : "Please enter your details to register for the program"}
            </div>
            {process.env.REACT_APP_TAYLORS_SIGN_IN == "true" ? (
              <div
                style={{
                  fontSize: matches ? 18 : 16,
                  marginBottom: matches ? 24 : 12,
                }}
              >
                To get started, please collaborate with your dedicated Area
                Manager to complete the following form. Once this process is
                successfully finalised with your Area Manager’s approval, you
                will promptly receive your login details via email.
              </div>
            ) : (
              <div
                style={{
                  fontSize: matches ? 18 : 16,
                  marginBottom: matches ? 24 : 12,
                }}
              >
                As soon as your application has been approved, you will receive
                an email confirming your account has been activated.
              </div>
            )}
            <div
              className={classes.headerItem}
              style={{
                fontSize: matches ? 24 : 18,
                fontWeight: "bold",
                marginBottom: matches ? 16 : 8,
              }}
            >
              About You
            </div>
            <div className={classes.textLabel}>
              Email<span className={classes.asterisk}>*</span>{" "}
            </div>
            <TextField
              value={email}
              // key={email}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={classes.textLabel}>
              First Name<span className={classes.asterisk}>*</span>{" "}
            </div>
            <TextField
              value={firstName}
              // key={firstName}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="firstName"
              name="firstName"
              autoComplete="firstName"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div className={classes.textLabel}>
              Last Name<span className={classes.asterisk}>*</span>{" "}
            </div>
            <TextField
              value={lastName}
              // key={lastName}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="lastName"
              name="lastName"
              autoComplete="lastName"
              onChange={(e) => setLastName(e.target.value)}
            />

            {process.env.REACT_APP_TAYLORS_SIGN_IN == "true" ? (
              <div className={classes.textLabel}>
                Taylors Site Code
                <span className={classes.asterisk}>*</span> <br />
                <span style={{ fontSize: 12 }}>(Area Manager to Complete)</span>
              </div>
            ) : (
              <div className={classes.textLabel}>
                Username<span className={classes.asterisk}>*</span> <br />
                <span style={{ fontSize: 12 }}>
                  We suggest something simple to remember such as your email
                  address
                </span>
              </div>
            )}
            <TextField
              value={username}
              // key={username}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
            />

            <div className={classes.textLabel}>
              Password<span className={classes.asterisk}>*</span> (Must be at
              least 8 characters long and include a number.){" "}
            </div>
            <TextField
              value={password}
              ref={passwordRef}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              type="password"
              id="password"
              name="password"
              autoComplete="password"
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError != ""}
              helperText={passwordError ? passwordError : ""}
            />

            <div className={classes.textLabel}>Confirm password</div>
            <TextField
              variant="outlined"
              margin="dense"
              minLength={8}
              maxLength={99}
              required
              fullWidth
              name="confirm-password"
              type="password"
              id="confirm-password"
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={confirmError != ""}
              helperText={confirmError ? confirmError : ""}
            />

            <div className={classes.textLabel}>
              Mobile
              {process.env.REACT_APP_MOBILE_UNREQUIRED != "true" && (
                <span className={classes.asterisk}>*</span>
              )}{" "}
              <br />
              {/* <span style={{ fontSize: 12 }}>
                Number must start with the country code +61 or +64
              </span> */}
            </div>

            <div className={classes.textInput}>
              <TextField
                value={mobile}
                ref={mobileRef}
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required={process.env.REACT_APP_MOBILE_UNREQUIRED != "true"}
                fullWidth
                id="mobile"
                name="mobile"
                onChange={(e) => {
                  e.target.value.match("^(?:[0-9+]*|)$") &&
                    setMobile(e.target.value);
                }}
              />
            </div>

            <div className={classes.textLabel}>
              Organisation/Company Name
              {process.env.REACT_APP_REQUIRE_ORGANISATION == "true" && (
                <span className={classes.asterisk}>*</span>
              )}
            </div>
            <TextField
              value={company}
              ref={companyRef}
              className={classes.textInput}
              variant="outlined"
              margin="dense"
              required={process.env.REACT_APP_REQUIRE_ORGANISATION == "true"}
              fullWidth
              id="company"
              name="company"
              autoComplete="company"
              onChange={(e) => setCompany(e.target.value)}
            />

            {process.env.REACT_APP_ACD_BRANCH == "true" &&
              storesList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>Branch</div>
                  <Select
                    value={selectedStore}
                    onChange={(e) => setSelectedStore(e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="member-group"
                    name="member-group"
                  >
                    {storesList.map((branch, i) => (
                      <MenuItem key={i} value={branch}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

            {process.env.REACT_APP_ACD_BRANCH == "true" &&
              managersList.length > 0 &&
              selectedStore && (
                <div>
                  <div className={classes.textLabel}>Branch Manager</div>
                  <Select
                    value={manager}
                    onChange={(e) => setManager(e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="member-group"
                    name="member-group"
                  >
                    {managersList.map((manager, i) => (
                      <MenuItem key={i} value={manager}>
                        {manager}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            {process.env.REACT_APP_REFERRAL == "true" &&
              memberGroupsList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>
                    Broker
                    <br />
                    <span style={{ fontSize: 12 }}>
                      Please select the staff member who referred you
                    </span>
                  </div>
                  <Select
                    value={memberGroup}
                    onChange={(e) => setMemberGroup(e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="member-group"
                    name="member-group"
                  >
                    {memberGroupsList.map((group, i) => (
                      <MenuItem key={i} value={group}>
                        {group}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

            <div className={classes.textLabel}>
              {process.env.REACT_APP_OKI_USER_CODE == "true" && (
                <div>
                  <div className={classes.textLabel}>
                    User Code
                    <span>
                      <span> (ABN/NZBN)</span>
                      <span className={classes.asterisk}>*</span>
                    </span>
                  </div>
                  <TextField
                    value={externalID}
                    // key={externalID}
                    className={classes.textInput}
                    variant="outlined"
                    margin="dense"
                    required={process.env.REACT_APP_OKI_USER_CODE == "true"}
                    fullWidth
                    id="externalID"
                    name="externalID"
                    autoComplete="externalID"
                    onChange={(e) => setExternalID(e.target.value)}
                  />
                </div>
              )}
            </div>

            <div
              className={classes.headerItem}
              style={{
                fontSize: matches ? 24 : 12,
                fontWeight: "bold",
                marginTop: 32,
              }}
            >
              Delivery Address
            </div>
            <Grid container direction="column">
              <div
                style={{
                  marginBottom: 8,
                  marginTop: 8,
                  fontSize: 16,
                  fontWeight: "normal",
                }}
              >
                Please note, we are unable to deliver to a PO Box.
              </div>
              <Grid item sm>
                <div className={classes.textLabel}>
                  Company Name{" "}
                  {(process.env.REACT_APP_CLIENT_ID == "11" ||
                  process.env.REACT_APP_CLIENT_ID == "12") ? (
                    <span className={classes.asterisk}>*</span>
                  ) : (
                    <span style={{ fontSize: 12 }}>(if applicable)</span>
                  )}
                </div>
                <TextField
                  value={companyName}
                  ref={companyRef}
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="company-name-street"
                  type="text"
                  fullWidth
                  required={
                    process.env.REACT_APP_CLIENT_ID == "11" ||
                    process.env.REACT_APP_CLIENT_ID == "12"
                  }
                  onChange={(e) => setCompanyName(e.target.value)}
                  error={companyNameError != ""}
                  helperText={companyNameError ? companyNameError : ""}
                />
              </Grid>
              <Grid item sm>
                <div className={classes.textLabel}>
                  Street Address<span className={classes.asterisk}>*</span>{" "}
                </div>
                <TextField
                  value={streetName}
                  // key={streetName}
                  required
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="street-name-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setStreetName(e.target.value)}
                  error={streetNameError != ""}
                  helperText={streetNameError ? streetNameError : ""}
                />
              </Grid>
              <Grid item sm>
                <div className={classes.textLabel}>
                  Suburb<span className={classes.asterisk}>*</span>{" "}
                </div>
                <TextField
                  value={suburb}
                  // key={suburb}
                  required
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="suburb-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setSuburb(e.target.value)}
                />
              </Grid>
              <Grid item sm>
                <div className={classes.textLabel}>
                  Post Code<span className={classes.asterisk}>*</span>{" "}
                </div>
                <TextField
                  value={postCode}
                  required
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="post-code-street"
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </Grid>
              {process.env.REACT_APP_CLIENT_ID != "11" &&
                process.env.REACT_APP_CLIENT_ID != "12" && (
                  <Grid item sm>
                    <div className={classes.textLabel}>City: </div>
                    <TextField
                      // key={city}
                      value={city}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="city-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                )}
              <div className={classes.textLabel}>
                State/Territory<span className={classes.asterisk}>*</span>{" "}
              </div>

              <StatesDropDown
                placeholder={"State/Territory"}
                setSelectedState={setState}
                state={state}
              />
              {/* </Grid>
              </Grid> */}
              <Grid item sm>
                <div className={classes.textLabel}>
                  Country<span className={classes.asterisk}>*</span>{" "}
                </div>
                <TextField
                  value={country}
                  // key={country}
                  required
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="country-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              style={{ marginTop: 16 }}
              control={
                <Checkbox
                  checked={checked}
                  required
                  onChange={handleChange}
                  name="terms"
                  color="primary"
                />
              }
              label={`Yes I have read and agree to the Terms & Conditions of ${customisation.theme.clientName}.`}
            />

            <div
              className={classes.conditions}
              style={{
                fontSize: 18,
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              {process.env.REACT_APP_CLIENT_ID == "12"
                ? "A condition of participation in Taylors Pour To Score is that each participant must read and agree to the published "
                : `A condition of participation in the ${customisation.theme.clientName} Program is that each participant must read and agree to the published `}
              <Link
                to={"/help/terms-conditions"}
                className={classes.conditions}
                style={{ textDecoration: "underline" }}
              >
                Terms & Conditions
              </Link>{" "}
              of the program
            </div>
            <ColorButton type="submit" fullWidth variant="contained">
              Register
            </ColorButton>
          </form>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <AuthDialog
        title={success ? "Success" : error ? "Something went wrong" : ""}
        message={
          success
            ? process.env.REACT_APP_AUTO_APPROVE_USER == "true"
              ? "Your registration has been accepted and you should receive a confirmation email confirming your membership."
              : "Your registration details have been sent to our team for approval and you will receive a confirmation email once it has been processed."
            : error
            ? error.error_description
            : ""
        }
        openDialog={success === true || dialogError}
      />
    </div>
  );
}
