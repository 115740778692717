import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import StatesDropDown from "../../common/StatesDropDown";
import { selectCustomisation } from "../customisation/customisationSlice";
import {
  fetchDistributors,
  getFullList as getFullDistributorsList,
  selectDistributors,
} from "../distributors/distributorsSlice";
import {
  fetchManagers,
  fetchMemberGroups,
  fetchMemberTypes,
  fetchReputations,
  selectMembers,
} from "../members/membersSlice";
import {
  fetchStores,
  getFullList as getFullStoresList,
  selectStores,
} from "../stores/storesSlice";
import { createUser } from "../users/usersSlice";
import { uuidv4 } from "../../app/utils";
import { fetchClientCountry, selectClients } from "../clients/clientsSlice.js";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "transparent",
    color: "#fff",
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#fff",
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: "#0000004f",
    },
    fontWeight: "bold",
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    width: "100%",
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.7,
    },
  },
}))(Button);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    // backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    // backgroundColor: theme.palette.secondary.main,
    // height: '100%',
  },
  form: {
    // borderBottom: '1px solid rgb(221, 221, 221)',

    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 8,
  },
  textInput: {},
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    // color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    marginLeft: 80,
  },

  userCardsContainerEdit: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
    marginRight: 80,

    // padding: 24,
    // boxSizing: 'border-box',
  },
  historyCardEdit: {
    flexGrow: 1,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  formControl: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 16,
    display: "flex",
    marginTop: 12,
  },
  addCircle: { marginRight: 0, color: "#FFF" },
  title: {
    color: "#fff",
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const GenerateRoleList = () => {
  let baseList = [
    { id: 2, value: "admin", name: "Admin" },
    { id: 99, value: "member", name: "Member" },
  ];

  if (process.env.REACT_APP_ENABLE_MANAGER == "true") {
    baseList = [...baseList, { id: 3, value: "manager", name: "Manager" }];
  }
  if (process.env.REACT_APP_ENABLE_SALES_REP == "true") {
    baseList = [...baseList, { id: 4, value: "sales", name: "Sales" }];
  }

  return baseList.sort((a, b) => a.id - b.id);
};

export default function AddUser(props) {
  const { userAccount } = props;
  const classes = useStyles();
  const history = useHistory();
  const { customisation } = useSelector(selectCustomisation);
  const client = useSelector(selectClients);

  const { distributorsList, distributorsAmount } =
    useSelector(selectDistributors);
  const { storesList, storesAmount } = useSelector(selectStores);
  const { memberTypesList, memberGroupsList, reputationList, managersList } =
    useSelector(selectMembers);

  const dispatch = useDispatch();
  // const {users, user, userAccount, signedIn} = useSelector(selectUsers);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [userCode, setUserCode] = useState("");
  const [company, setCompany] = useState("");
  // const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 5, value: "Pending", name: "Pending" },
  ]);
  const [roleList, setRoleList] = useState(GenerateRoleList);
  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState(1);
  const [roleOpen, setRoleOpen] = useState(false);

  const [distributor, setDistributor] = useState(null);
  const [store, setStore] = useState(null);
  const [memberGroup, setMemberGroup] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [reputation, setReputation] = useState(null);
  const [manager, setManager] = useState(null);

  const firstUpdateDist = useRef(true);
  const firstUpdateStore = useRef(true);

  const [role, setRole] = useState(99);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleRoleClose = () => {
    setRoleOpen(false);
  };

  const handleRoleOpen = () => {
    setRoleOpen(true);
  };

  const validatePassword = () => {
    var conditions = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,99}$/;
    if (password.match(conditions)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(fetchClientCountry({}));
  }, []);

  useEffect(() => {
    if (!distributorsList.length) {
      dispatch(
        fetchDistributors({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!storesList.length) {
      dispatch(
        fetchStores({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!memberTypesList.length) {
      dispatch(
        fetchMemberTypes({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!memberGroupsList.length) {
      dispatch(
        fetchMemberGroups({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!managersList.length) {
      dispatch(
        fetchManagers({
          token: jwtToken,
          query: `?role_id=4`,
        })
      );
    }
    if (!reputationList.length) {
      dispatch(
        fetchReputations({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (store) {
      dispatch(
        fetchManagers({
          token: jwtToken,
          query: `?role_id=4&store_id=${store.id}`,
        })
      );
    }
  }, [store])

  useEffect(() => {
    if (
      distributorsList.length &&
      distributorsList.length != distributorsAmount &&
      firstUpdateDist.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullDistributorsList({
          token: jwtToken,
          offset: distributorsList.length,
          query: searchQuery,
          targetLength: distributorsAmount,
        })
      );
      firstUpdateDist.current = false;
    }
  }, [distributorsList.length]);

  useEffect(() => {
    if (
      storesList.length &&
      storesList.length != storesAmount &&
      firstUpdateStore.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullStoresList({
          token: jwtToken,
          offset: storesList.length,
          query: searchQuery,
          targetLength: storesAmount,
        })
      );
      firstUpdateStore.current = false;
    }
  }, [storesList.length]);

  let jwtToken = localStorage.getItem("clientToken");
  const handleSubmit = (event) => {
    event.preventDefault();
    const userDetails = {
      statusID: status,
      roleID: role,
      clientID: Number(process.env.REACT_APP_CLIENT_ID),
      username: username,
      company: company,
      firstName: firstName,
      lastName: lastName,
      displayName: firstName + " " + lastName,
      email: email,
      mobile:
        mobile == ""
          ? uuidv4()
          : mobile.startsWith("+")
          ? mobile
          : client.country.countryName == "Australia"
          ? "+61" + mobile.replace(/^0+/, "")
          : "+64" + mobile.replace(/^0+/, ""),
      externalID: userCode,
      password: password,
      postalAddress: {
        // streetNumber: streetNumber,
        companyName: companyName,
        streetName: streetName,
        suburb: suburb,
        postCode: postCode,
        city: city,
        state: state,
        country: country,
      },
    };

    if (distributor) {
      userDetails["distributorID"] = distributor.id;
    }
    if (store) {
      userDetails["storeID"] = store.id;
    }
    if (memberGroup) {
      userDetails["memberGroupID"] = memberGroup.id;
    }
    if (memberType) {
      userDetails["memberTypeID"] = memberType.id;
    }
    if (reputation) {
      userDetails["reputationID"] = reputation.id;
    }
    if (manager) {
      userDetails["managerID"] = manager.id;
    }
    dispatch(createUser({ token: jwtToken, user: userDetails }));
  };
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setUsername("");
    setFirstName("");
    setLastName("");
    setDisplayName("");
    setEmail("");
    setMobile("");
    setUserCode("");
    setRole(99);
    setStatus(1);
    setPassword("");
    // setStreetNumber("");
    setStreetName("");
    setCompanyName("");
    setSuburb("");
    setPostCode("");
    setCity("");
    setState("");
    setCountry("");
    setOpen(true);
  };

  const handleClose = () => {
    setUsername("");
    setFirstName("");
    setLastName("");
    setDisplayName("");
    setEmail("");
    setMobile("");
    setUserCode("");
    setRole(99);
    setStatus(1);
    setPassword("");
    // setStreetNumber("");
    setStreetName("");
    setCompanyName("");
    setSuburb("");
    setPostCode("");
    setCity("");
    setState("");
    setCountry("");
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label={"add member"} style={{ marginLeft: 0 }}>
        <AddCircleIcon
          className={classes.addCircle}
          onClick={handleClickOpen}
        />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-dialog-title"
        >
          {"Add New Member"}
        </DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText style={{ paddingTop: 8, textAlign: "center" }}>
              <div className={classes.userDetails}>
                <div className={classes.actionsItem}>
                  <div
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Set Member Role
                  </div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="role-select-label"
                      id="role-select"
                      open={roleOpen}
                      onClose={handleRoleClose}
                      onOpen={handleRoleOpen}
                      value={role}
                      displayEmpty
                      onChange={handleRoleChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={<Input className={classes.storeSelect} />}
                      // input={<Input className={classes.brandSelect} />}
                      // renderValue={(selected) => selected.join(', ')}
                      // renderValue={(selected) => sortCategory}
                      MenuProps={MenuProps}
                      // displayEmpty
                    >
                      {roleList.map((role) => (
                        <MenuItem
                          // style={{color: theme.palette.primary.main}}
                          value={role.id}
                        >
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.actionsItem}>
                  <div
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Set Member Status
                  </div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="store-select-label"
                      id="store-select"
                      open={statusOpen}
                      onClose={handleStatusClose}
                      onOpen={handleStatusOpen}
                      value={status}
                      displayEmpty
                      onChange={handleStatusChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={<Input className={classes.storeSelect} />}
                      // input={<Input className={classes.brandSelect} />}
                      // renderValue={(selected) => selected.join(', ')}
                      // renderValue={(selected) => sortCategory}
                      MenuProps={MenuProps}
                      // displayEmpty
                    >
                      {statusList.map((status) => (
                        <MenuItem
                          // style={{color: theme.palette.primary.main}}
                          value={status.id}
                        >
                          {status.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </DialogContentText>
            <Grid className={classes.historyCardEdit}>
              <div
                style={{
                  fontSize: 28,
                  marginBottom: 18,
                  fontWeight: "bold",
                  marginTop: 24,
                }}
              >
                Member Details
              </div>
              <div className={classes.textLabel}>First Name: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="firstName"
                name="firstName"
                autoComplete="firstName"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <div className={classes.textLabel}>Surname: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />

              <div className={classes.textLabel}>Username: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className={classes.textLabel}>Email: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className={classes.textLabel}>
                Mobile:
                <br />
                <span style={{ fontSize: 12 }}>
                  Number must start with the country code +61 or +64
                </span>
              </div>

              <div className={classes.textInput}>
                <TextField
                  defaultValue={mobile}
                  // key={mobile}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required={process.env.REACT_APP_MOBILE_UNREQUIRED != "true"}
                  fullWidth
                  id="mobile"
                  name="mobile"
                  autoComplete="mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div className={classes.textLabel}>
                Organisation/Company Name:{" "}
              </div>
              <TextField
                defaultValue={company}
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                fullWidth
                id="company"
                required
                name="company"
                autoComplete="company"
                onChange={(e) => setCompany(e.target.value)}
              />
              <div className={classes.textLabel}>
                User Code
                {process.env.REACT_APP_OKI_USER_CODE == "true" && (
                  <span> (ABN/NZBN)</span>
                )}
                :{" "}
              </div>
              <TextField
                defaultValue={userCode}
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                fullWidth
                id="userCode"
                name="userCode"
                autoComplete="userCode"
                onChange={(e) => setUserCode(e.target.value)}
              />
              {distributorsList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>Distributor</div>
                  <Autocomplete
                    onChange={(event, distributor) => {
                      setDistributor(distributor);
                    }}
                    id={`combo-box-distributor`}
                    options={distributorsList}
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.name}
                    value={distributor}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              {storesList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>{process.env.REACT_APP_CLIENT_ID != "17" ? "Store" : "Branch"}</div>
                  <Autocomplete
                    onChange={(event, store) => {
                      setStore(store);
                    }}
                    id={`combo-box-store`}
                    options={storesList}
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.name}
                    value={store}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              {memberGroupsList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>Member Group</div>
                  <Autocomplete
                    onChange={(event, group) => {
                      setMemberGroup(group);
                    }}
                    id={`combo-box-store`}
                    options={
                      process.env.REACT_APP_REFERRAL == "true" && store
                        ? memberGroupsList.filter(
                            (group) => group.storeID == store.id
                          )
                        : memberGroupsList
                    }
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.name}
                    value={memberGroup}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              {managersList.length > 0 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true" && (
                <div>
                  <div className={classes.textLabel}>{process.env.REACT_APP_ACD_BRANCH != "true" ? "Supervisor" : "Branch Manager"}</div>
                  <Autocomplete
                    onChange={(event, group) => {
                      setManager(group);
                    }}
                    id={`combo-box-store`}
                    options={managersList}
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.displayName}
                    value={manager}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              {memberTypesList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>Member Type (Group)</div>
                  <Autocomplete
                    onChange={(event, type) => {
                      setMemberType(type);
                    }}
                    id={`combo-box-store`}
                    options={memberTypesList}
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.name}
                    value={memberType}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              {reputationList.length > 0 && (
                <div>
                  <div className={classes.textLabel}>Reputation</div>
                  <Autocomplete
                    onChange={(event, rep) => {
                      setReputation(rep);
                    }}
                    id={`combo-box-store`}
                    options={reputationList}
                    getOptionSelected={(option, value) => option.id == value}
                    getOptionLabel={(option) => option.name}
                    value={reputation}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}

              <div className={classes.textLabel}>Password: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="password"
                name="password"
                autoComplete="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div
                style={{
                  fontSize: 28,
                  marginBottom: 18,
                  fontWeight: "bold",
                  marginTop: 24,
                }}
              >
                Delivery Address
              </div>
              <Grid container direction="column">
                {/* <Grid item sm> */}
                {/* <Grid container direction="row" spacing={2}>
                <Grid item sm> */}
                {/* <div className={classes.textLabel}>PO Box<span className={classes.asterisk}>*</span> </div>
              <TextField
              defaultValue={userAccount.}
                className={classes.textItem}
                variant="outlined"
                
                margin="dense"
                id="street-po"
                type="text"
                fullWidth
                onChange={(e) => setPoBox(e.target.value)}
              />
            </Grid> */}
                {/* <Grid item sm>
          <div className={classes.textLabel}>Street Number: </div>
          <TextField
   
            className={classes.textItem}
            variant="outlined"
            margin="dense"
            id="street-number-street"
            type="text"
            fullWidth
            onChange={(e) => setStreetNumber(e.target.value)}
          />
        </Grid> */}

                <Grid item sm>
                  <div className={classes.textLabel}>
                    Company Name{" "}
                    <span style={{ fontSize: 12 }}>(if applicable)</span>:{" "}
                  </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="company-number-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
                <Grid item sm>
                  <div className={classes.textLabel}>Street Address: </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="street-number-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setStreetName(e.target.value)}
                  />
                </Grid>
                {/* <Grid item sm>
              <Grid container direction="row" spacing={2}> */}
                <Grid item sm>
                  <div className={classes.textLabel}>Suburb: </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="subarb-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                </Grid>
                <Grid item sm>
                  <div className={classes.textLabel}>Post Code: </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="post-code-street"
                    type="text"
                    inputProps={{ maxLength: 4 }}
                    fullWidth
                    onChange={(e) => setPostCode(e.target.value)}
                  />
                </Grid>
                {/* </Grid>
            </Grid> */}
                {/* <Grid item sm>
              <Grid container direction="row" spacing={2}> */}
                {process.env.REACT_APP_CLIENT_ID != "11" &&
                  process.env.REACT_APP_CLIENT_ID != "12" && (
                    <Grid item sm>
                      <div className={classes.textLabel}>City: </div>
                      <TextField
                        className={classes.textItem}
                        variant="outlined"
                        margin="dense"
                        id="city-street"
                        type="text"
                        fullWidth
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                  )}
                <Grid item sm>
                  <div className={classes.textLabel}>State/Territory: </div>
                  {/* <TextField
        
         
            className={classes.textItem}
            variant="outlined"
            margin="dense"
            id="state-street"
            type="text"
            fullWidth
            onChange={(e) => setState(e.target.value)}
          /> */}
                  <StatesDropDown
                    placeholder={"State/Territory"}
                    setSelectedState={setState}
                    state={state}
                  />
                </Grid>
                {/* </Grid>
            </Grid> */}
                <Grid item sm>
                  <div className={classes.textLabel}>Country: </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="country-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions
            style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
          >
            <Grid container spacing={2} direction="row">
              <Grid item sm>
                <ColorButton onClick={handleClose} color="primary">
                  Cancel
                </ColorButton>
              </Grid>
              <Grid item sm>
                <ColorButton type="submit" color="secondary">
                  {"Create User"}
                </ColorButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
