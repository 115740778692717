import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";
import { fetchUserClaims } from "../claims/claimsSlice";
import { fetchUserOrders } from "../orders/ordersSlice";
import { fetchUserPointsHistory } from "../userPoints/userPointsSlice";
import {
  fetchMoreUsers,
  fetchUserByID,
  fetchUsers,
  getFullList,
  selectUsers,
  setUser,
} from "./usersSlice";
import {
  fetchStores,
  getFullList as getFullStoresList,
  selectStores,
} from "../stores/storesSlice";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

const GenerateRoleList = (userAccount) => {
  if (userAccount.roleID <= 2) {
    let baseList = [
      { id: 1, value: "super_admin", name: "Super Admin" },
      { id: 2, value: "admin", name: "Admin" },
      { id: 99, value: "member", name: "Member" },
    ];

    if (process.env.REACT_APP_ENABLE_MANAGER == "true") {
      baseList = [...baseList, { id: 3, value: "manager", name: "Manager" }];
    }
    if (process.env.REACT_APP_ENABLE_SALES_REP == "true") {
      baseList = [...baseList, { id: 4, value: "sales", name: "Sales" }];
    }

    return baseList.sort((a, b) => a.id - b.id);
  } else {
    return [{ id: 99, value: "member", name: "Member" }];
  }
};

export default function UsersAdminContainer(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState([]);
  const [roleNames, setRoleNames] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [username, setUsername] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [roleIDs, setRoleIDs] = useState([]);

  const { storesList, storesAmount } = useSelector(selectStores);

  const { loading, usersList, usersAmount, lastQuery, userAccount } =
    useSelector(selectUsers);
  const rolesList = GenerateRoleList(userAccount);


  const firstUpdateStore = useRef(true);

  useEffect(() => {
    if (!storesList.length) {
      dispatch(
        fetchStores({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      storesList.length &&
      storesList.length != storesAmount &&
      firstUpdateStore.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullStoresList({
          token: jwtToken,
          offset: storesList.length,
          query: searchQuery,
          targetLength: storesAmount,
        })
      );
      firstUpdateStore.current = false;
    }
  }, [storesList.length]);
  

  const [storeNames, setStoreNames] = useState([]);
  const [storeIDs, setStoreIDs] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 5, value: "Pending", name: "Pending" },
  ]);

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("UsersAdminTable")
      ? JSON.parse(localStorage.getItem("UsersAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    localStorage.setItem("UsersAdminTable", JSON.stringify(tablePreferences));
  }, [tablePreferences]);

  const [numberShown, setNumberShown] = useState(25);

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "User #",
      long: false,
      sortable: true,
    },
    {
      id: "displayName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Display Name",
      long: true,
      sortable: true,
    },
    {
      id: "username",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Username",
      long: false,
      sortable: true,
    },
    {
      id: "mobile",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Mobile",
      long: false,
    },
    {
      id: "email",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email",
      long: false,
      sortable: true,
    },
    {
      id: "company",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Organisation/Company Name",
      long: false,
      sortable: false,
    },
    {
      id: "externalID",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "User Code",
      long: false,
      sortable: false,
    },
    {
      id: "redeemablePoints",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Unspent Points",
      long: false,
      sortable: true,
    },
    {
      id: "redeemedPoints",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Spent Points",
      long: false,
      sortable: true,
    },
    {
      id: "purchasedPoints",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Purchased Points",
      long: false,
      sortable: true,
    },
    {
      id: "pendingPoint",
      checked: false,
      numeric: false,
      disablePadding: false,
      label: "Pending Points",
      long: false,
      sortable: true,
    },
    {
      id: "refundedPoints",
      checked: false,
      numeric: false,
      disablePadding: false,
      label: "Refunded Points",
      long: false,
      sortable: true,
    },
    {
      id: "totalPointsEarned",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Total Points",
      long: false,
      sortable: true,
    },
    {
      id: "pointsMonetary",
      checked: userAccount.roleID <= 2,
      numeric: true,
      disablePadding: false,
      label: "Points Value",
      long: false,
    },
    {
      id: "role",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "User Role",
      long: false,
    },
    {
      id: "status",
      numeric: false,
      sortable: true,
      disablePadding: false,
      label: "Status",
      checked: true,
      long: false,
    },
    {
      id: "lastLogin",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Last Login",
      long: false,
    },
    {
      id: "loginCount",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Logins",
      long: false,
      sortable: true,
    },
    {
      id: "createdAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Account Created",
      long: false,
      sortable: true,
    },
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Last Updated",
      long: false,
      sortable: true,
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Company Name",
      long: false,
      object: true,
      objectProperty: "companyName",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Street Address",
      long: false,
      object: true,
      objectProperty: "streetName",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Suburb",
      long: false,
      object: true,
      objectProperty: "suburb",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "City",
      long: false,
      object: true,
      objectProperty: "city",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Post Code",
      long: false,
      object: true,
      objectProperty: "postCode",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "State",
      long: false,
      object: true,
      objectProperty: "state",
    },
    {
      id: "postalAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Country",
      long: false,
      object: true,
      objectProperty: "country",
    },
    {
      id: "managerName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Manager Name",
      long: false,
      sortable: false,
    },
    {
      id: "managerEmail",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Manager Email",
      long: false,
      sortable: false,
    },
    // {
    //   id: "managerPhone",
    //   checked: true,
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Manager Phone",
    //   long: false,
    //   sortable: false,
    // },
    {
      id: "managerMobile",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Manager Mobile",
      long: false,
      sortable: false,
    },
    {
      id: "storeName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Store/Branch Name",
      long: false,
      sortable: false,
    },
    {
      id: "memberGroupName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Member Group",
      long: false,
      sortable: false,
    },
    {
      id: "directManagerName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Supervisor Name",
      long: false,
      sortable: false,
    },
    {
      id: "memberTypeName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Member Group Type",
      long: false,
      sortable: false,
    },
    {
      id: "reputationName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Tier Name",
      long: false,
      sortable: false,
    },
  ];

  const handleRolesChange = (event) => {
    let idsArr = [];

    let selectedRole = rolesList.filter(
      (role) => role.name === event.target.value
    );

    selectedRole && idsArr.push(selectedRole[0].id);

    setRoleNames(event.target.value);
    setRoleIDs(idsArr);
  };

  const handleStoreChange = (event) => {
    let idsArr = [];

    let selectedStore = storesList.filter(
      (store) => store.name === event.target.value
    );

    selectedStore && idsArr.push(selectedStore[0].id);

    setStoreNames(event.target.value);
    setStoreIDs(idsArr);
  };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    if (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true") {
      searchQuery = replaceUrlParam(searchQuery, "manager_id", userAccount.id);
    }

    dispatch(
      fetchUsers({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true")
          ? `?limit=${
              tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
            }&manager_id=${userAccount.id}`
          : `?limit=${
              tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
            }`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true") {
      searchQuery = replaceUrlParam(searchQuery, "manager_id", userAccount.id);
    } else {
      searchQuery = replaceUrlParam(searchQuery, "manager_id", "");
    }
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullList({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > usersList.length
            ? tablePreferences.rowsPerPage
            : usersList.length,
        query: searchQuery,
        targetLength: usersAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      let searchQuery = lastQuery ? lastQuery : "";

      if (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true") {
        searchQuery = replaceUrlParam(
          searchQuery,
          "manager_id",
          userAccount.id
        );
      } else {
        searchQuery = replaceUrlParam(searchQuery, "manager_id", "");
      }
      if (usersList.length < usersAmount && usersList.length < expectedLen) {
        dispatch(
          fetchMoreUsers({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));

    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
      case "statusID":
        property = "status_id";
        break;
      case "displayName":
        property = "display_name";
        break;
      case "loginCount":
        property = "login_count";
        break;
      case "redeemablePoints":
        property = "redeemable";
        break;
      case "redeemedPoints":
        property = "redeemed";
        break;
      case "purchasedPoints":
        property = "purchased";
        break;
      case "refundedPoints":
        property = "refunded";
        break;
      case "pendingPoints":
        property = "pending";
        break;
      case "totalPointsEarned":
        property = "total_points_earned";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    if (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true") {
      searchQuery = replaceUrlParam(searchQuery, "manager_id", userAccount.id);
    }

    dispatch(
      fetchUsers({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {
    dispatch(
      fetchUserByID({
        id: id,
        token: jwtToken,
      })
    );
  };

  const handleEditClick = (id) => {
    const selectedUser = usersList.filter((user) => user.id === id);
    dispatch(setUser(selectedUser[0]));
    dispatch(
      fetchUserClaims({
        token: jwtToken,
        userID: id,
      })
    );
    dispatch(
      fetchUserPointsHistory({
        token: jwtToken,
        userID: id,
      })
    );
    dispatch(
      fetchUserOrders({
        token: jwtToken,
        userID: id,
      })
    );
    history.push(`/admin/members/${id}`);
  };

  const handleSearch = () => {
    const encodedEmail = encodeURIComponent(userEmail);
    let searchQuery = lastQuery ? lastQuery : "";

    if (statusIDs.length) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "statuses",
        statusIDs.join(",")
      );
    }
    if (roleIDs.length) {
      searchQuery = replaceUrlParam(searchQuery, "roles", roleIDs.join(","));
    }
    if (storeIDs.length) {
      searchQuery = replaceUrlParam(searchQuery, "store_id", storeIDs.join(","));
    }
    // if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "name", searchTerm);
    // }
    if (username) {
      searchQuery = replaceUrlParam(searchQuery, "username", username);
    }
    if (encodedEmail) {
      searchQuery = replaceUrlParam(searchQuery, "email", encodedEmail);
    }
    if (userMobile) {
      searchQuery = replaceUrlParam(searchQuery, "mobile", userMobile);
    }

    if (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true") {
      searchQuery = replaceUrlParam(searchQuery, "manager_id", userAccount.id);
    }

    dispatch(
      fetchUsers({
        query: searchQuery,
        token: jwtToken,
      })
    );

    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {
    setRoleNames([]);
    setStoreNames([]);
    setUserEmail("");
    setUsername("");
    setSearchTerm("");
    setUserMobile("");
    setRoleIDs([]);
    setStoreIDs([]);
    setStatusNames([]);
    setStatusIDs([]);

    dispatch(
      fetchUsers({
        token: jwtToken,
        query:
        (userAccount.roleID == 4 && process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true")
            ? `?limit=${tablePreferences.rowsPerPage}&manager_id=${userAccount.id}`
            : `?limit=${tablePreferences.rowsPerPage}`,
      })
    );

    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const selectNames = ["role", "status", "branch"];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded") {
    content = (
      <CustomTable
        data={usersList}
        actionsLists={[rolesList, statusList, storesList]}
        actionsHandleChange={[handleRolesChange, handleStatusChange, handleStoreChange]}
        actionsNames={[roleNames, statusNames, storeNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        username={username}
        userMobile={userMobile}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        setUsername={setUsername}
        setUserMobile={setUserMobile}
        setSearchTerm={setSearchTerm}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"user"}
        selectNames={selectNames}
        productsAmount={usersAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
        restricted={userAccount.roleID > 2}
      />
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
